@header_hight: 65px;
@tab_height: 30px;
@header_height: 45px;


.center{
  margin: 0 auto;
}

.bgwhite{
  background-color: #fff;
}

.colflex{
  display: flex;
  flex-direction: column;
}

.rowflex{
  display: flex;
  flex-direction: row;
}

.rowFlexWrap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.hp100{
  height: 100%;
}

.wp100{
  width: 100%;
}

.flexfill{
  flex: 1;
  min-height: 0;
}

.flexfillX{
  flex: 1;
  min-width: 0;
}

.autoFlexFillY{
  flex: auto;
  min-height: 0;
}

.scrollY{
  overflow: auto;
  overflow-x: hidden;
}

.autoScrollY{
  overflow-y: auto;
}

.loading{
  height: 100%;
  width: 100%;
  text-align: center;
}

//布局
.fixed{
  position: fixed;
}

.iblock{
  display: inline-block;
}

.block{
  display: block;
}

.p08{
  padding: 0 8px;
}

.p20_0{
  padding: 0 20px;
}

span[type="link"]{
  display: inline-block;
  height: 100%;
  padding: 0 10px;
  user-select: none;
  &:hover{
    cursor: pointer;
    color:rgba(50, 24, 197, 0.8)
  }
}

.login{
  display: flex;
  flex-direction: column;
  height: 100%;
  background: url(./images/bg.jpg) no-repeat center center;
  
  &-link{
    display: inline-block;

    height: @header_hight;
    line-height: @header_hight;
    color: #fff;
    font-size: 18px;
  }

  &-logo{
    display: block;
    float: left;
    height: 42px;
    margin: 10px 10px;
  }

  &-container{
    position: relative;
    width: 1200px;
    margin: 0 auto;
  }

  &-header{
    background-color: rgba(#000, 0.85);
  }

  &-main{
    flex: auto;
  }

  &-wrapper{
    position: absolute;
    right: 120px;
    top: 60px;
    width: 300px;
    padding: 20px;
    background-color: rgba(#fff, 0.8);
  }

  &-footer{
    background-color: #000;
    padding: 10px 0;
    & p{
      margin-bottom: 0;
      height: 20px;
      font-size: 12px;
      text-align: center;
      color: #fff;
      line-height: 20px;
    }
  }

  &-words{
    height: 32px;
    line-height: 32px;
    font-size: 18px;
    font-weight: 600;
    color:rgba(0, 0, 0, 0.85);
  }

  &-form{
    width: 260px;
  }


  &-forget{
    float: right;
  }

  &-submit{
    width: 100%;
  }
}

.admin{
  height: 100%;
  background: url(./images/admin_bg.jpg) no-repeat center center;

  .ant-layout{
    background-color: rgba(255, 255, 255, 0);
  }


  .header{
    display: flex;
    color: #fff;
    opacity: .75;
    height: 46px;
    line-height: 46px;


    .sysname{
      font-size: 1.6rem;
      font-weight: bold;
      flex: auto;
    }

    .menu{
      font-size: 1.4rem;

      .trigger{
        display: inline-block;
        cursor: pointer;
        &:hover{
          color:rgba(255, 255, 255, 0.65)
        }
      }

      &__trigger{
        height: 46px;
        line-height: 46px;
        padding: 0 20px;
        font-size: 1.2rem;
      }
    }
  }

  .content{
    width: 1200px;
    margin: 0 auto;

    .linklist{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: flex-start;
      margin-top: 30px;

      &__item{
        margin: 25px;
        opacity: .8;
        border-radius: 5px;
        background-color: #f00;
        &:hover{
          background-color: #f66;
        }

        .link{
          display: inline-block;
          .image{
            height: 80px;
            width: 80px;
          }
        }

        .name{
          float: right;
          margin-top: 20px;
          color: rgba(255, 255, 255, .8);
          height: 60px;
          width: 80px;
          text-align: center;
        }
      }
    }
  }
}


.nav{
  height: 46px;
  line-height: 46px;
  color: white;
  display: flex;

  .back{
    display: inline-block;
    cursor: pointer;
    font-size: 1.4rem;
    color:rgba(255, 255, 255, .65);
    padding: 0 10px;

    &:hover{
      background: #0d0da8;
      color: #fff;
    }
  }

  .name{
    margin:0 20px;
    font-size: 1.8rem;
  }



}


.register{
  height: 100%;
  overflow-x: hidden;
  background-color: #f0f2f5;


  .header{
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    padding: 0 40px;
    margin-bottom: 40px;
  }

  .content{
    width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    

    .steps{
      width: 600px;
      margin: 0 auto;
      padding: 20px 0;
    }

  }

}



.expert{
  height: 100%;
  .header{
    height: 46px;
    padding: 0;
  }
}


.expertl{
  height: 100%;
}


.expertc{
  width: 1200px;
  height: 100%;

  &__wrapper{
    height: 100%;
  }

  &__step{
    width: 600px;
    padding: 20px 0;
  }
}


.experti{
  width: 800px;
  height: 100%;
  padding: 20px;
}


.flayout{
  height: 100%;
}

/*注册类型*/
.regtype{
  width: 700px;
  min-height: 500px;
  flex-wrap: wrap;

  .wapper{
    padding-top: 20px;
  }

  &__title{
    text-align: center;
    padding: 20px 0;
    color:#9a9a9a;
  }

  &__item{
    margin: 10px;
    border: 1px solid  #e7e7eb;
    width: 328px;

    &:hover{
      background-color: #e7e7eb;
    }
  }

  .link{
    color:#222;
    padding: 25px 10px;
    &:hover{
      color:#222;
      background-color:#f6f8f9;
    }
  }

  .desc{
    text-align: center;
    color:#9a9a9a;
    margin-bottom: 0;
  }

  .title{
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    
    &::before{
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 44px;
      height: 44px;
      margin-right: 10px;
    }

    &__company::before{
      background: url(~/static/images/account_type.png) 0 -48px no-repeat;
    }

    &__expert::before{
      background: url(~/static/images/account_type.png) 0 0 no-repeat;
    }
  }
}


.service{
  
  & .h4{
    font-size: 24px;
    text-align: center;
    font-weight: 600;
  }

  & .h5{
    font-size: 16px;
    text-align: center;
  }

  & p{
    padding: 0;
    margin: 10px 0;
    text-indent: 20px;
    text-align: justify;
    line-height: 1.8;
  }

  & dl{
    text-indent: 20px;
  }

}


.score-table{

  display: block;

  & tr, & td, & th{
      border: 1px solid #000;
  }

}

/******************首页***************/
.wrap{
  width: 1200px;
  margin: 0 auto;
}

.hd{
  display: flex;
  flex-direction: row;


  & .logo, & .nv{
    height: 60px;
    line-height: 60px;
  }

  & .logo{
    width: 120px;
    height: 60px;
  }

  &__span{
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    &::before{
      content: '|';
      font-weight: bold;
      margin-right: 6px;
    }
  }

  & .nv{
    flex: 1;

    &__ul{
      float: right;
      & > li{
        display: inline-block;
        margin-left: 20px;
      }
    }

    &__link{
      font-size: 14px;
      color:rgba(0, 0, 0, 0.85);

      &:hover{
        color:rgba(0, 0, 0, 0.65);
      }
    }
  }
}

.ban{
  width: 100%;
  height: 370px;
}

.ft{
  padding: 20px 0;

  &__center{
    text-align: center;
  }

  &__ul{
    margin: 0;
  }

  &__item{
    display: inline-block;
    margin: 0 20px;
  }

  &__link{
    font-size: 14px;
    display: inline-block;
  }

  &__copyright{
    font-size: 12px;
  }

  &__friend{
    margin-right: 20px;
    font-size: 12px;
  }
}

.case{
  height: 200px;
  background: url(./images/index/banner5.png) repeat-x center bottom;
  background-size: 30%;
  background-color: #fff;

  &__title{
    background-color: #1c2336;
  }

  &__ul{
    display: flex;
    flex-direction: row;
    margin: 0;

    & > li{
      flex: 1;
      text-align: center;
    }
  }

  &__link{
    height: 40px;
    line-height:40px;
    font-size: 14px;
    cursor: pointer;

    user-select: none;
    color:rgba(255, 255, 255, .65);

    &:hover{
      color:rgba(255, 255, 255, .8);
      border-bottom: 4px solid #a5adb8;
    }
  }

  &__active{
    color:rgba(255, 255, 255, .8);
    border-bottom: 4px solid #a5adb8;
  }

  &__body{

    &:hover{
      transform: translateY(-12px);
    }

    transition: all .25s linear;

    margin-top: 20px;
    border-radius: 6px;
    padding: 6px;

    background-color: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 7px #e6e6e6;
    box-shadow: 0 0 7px #e6e6e6;
    cursor: pointer;
    outline: none;
  }

  &__image{
    display: block;
    height: 68px;
    border-radius: 6px;
    position: relative;
    & > img{
      display: block;
      width: 100%;
    }
  }

  &__shadow{
    position: absolute;
    background: rgba(0, 0, 0, 0.35);
    top: 0;
    width: 100%;
    height: 68px;
  }

  &__name{
    position: absolute;
    margin: 6px;
    font-size: 12px;
    color: rgba(#fff, 0.8);
    bottom: 0;
    left: 0;
    user-select: none;
  }
}

.ttl{
  font-size: 20px;
  font-weight: 400;
  border-bottom: 1px solid #ddd;
  padding-bottom: 6px;
}


.article{

  & > p{
    text-indent: 32px;
    font-size: 16px;
    line-height: 1.8;
    text-align: justify;
    text-justify: auto;
    padding-bottom: 20px;
    margin: 0;
  }
}

.new{
  position: relative;
  font-size: 16px;
  line-height: 1.8;


  &__type{
    display: inline-block;
    width: 30px;
    text-align: right;
  }

  &__publish{
    position: absolute;
    right: 0;
    color:rgba(0, 0, 0, 0.35);
  }
}


.frame{
  height: 100vh;
  display: flex;
	flex-wrap:nowrap;
	flex-direction: column;

  &__header{
    flex: auto;
  }
}


.profile{
  width: 300px;
  height: 200px;
  & > p{
    text-indent: 20px;
  }
}
@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);